import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AddTour from './components/AddTour';
import React from 'react';
import Sidebar from './components/Sidebar';
import TourDetails from './components/TourDetails';
import TourList from './components/TourList';

const App = () => {
  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-9 main-content">
            <Routes>
              <Route path="/add-tour" element={<AddTour />} />
              <Route path="/tour-list" element={<TourList />} />
              <Route path="/tour-details" element={<TourDetails />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
