import './AddTour.css';

import { Button, Container, FormControl, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

// Custom CSS
const AddTour = () => {
  const [tourDetails, setTourDetails] = useState({
    title: '',           // Tour Title
    description: '',     // Tour Description
    price: '',           // Price per person
    duration: '',        // Duration in days
    locations: '',       // Tour Locations
    availableDates: '',  // Available Dates
    maxGroupSize: '',    // Max Group Size
    type: '',            // Tour Type (hiking, camping, etc.)
    images: []           // Array to store selected images
  });

  const handleChange = (e) => {
    setTourDetails({ ...tourDetails, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    setTourDetails({ ...tourDetails, images: [...files] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(tourDetails);
  };

  return (
    <Container className="add-tour-container">
      <Typography variant="h4" gutterBottom className="heading" style={{ fontWeight: 600 }}>
        Add New Tour
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Tour Title */}
          <Grid item xs={12}>
            <TextField
              label="Tour Title"
              variant="outlined"
              fullWidth
              name="title"
              value={tourDetails.title}
              onChange={handleChange}
              className="text-field"
            />
          </Grid>

          {/* Tour Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              name="description"
              value={tourDetails.description}
              onChange={handleChange}
              multiline
              rows={4}
              className="text-field"
            />
          </Grid>

          {/* Price per Person */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Price per Person"
              variant="outlined"
              fullWidth
              name="price"
              value={tourDetails.price}
              onChange={handleChange}
              className="text-field"
            />
          </Grid>

          {/* Duration */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Duration (in days)"
              variant="outlined"
              fullWidth
              name="duration"
              value={tourDetails.duration}
              onChange={handleChange}
              className="text-field"
            />
          </Grid>

          {/* Tour Locations */}
          <Grid item xs={12}>
            <TextField
              label="Tour Locations"
              variant="outlined"
              fullWidth
              name="locations"
              value={tourDetails.locations}
              onChange={handleChange}
              className="text-field"
            />
          </Grid>

          {/* Available Dates */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Available Dates"
              variant="outlined"
              fullWidth
              name="availableDates"
              value={tourDetails.availableDates}
              onChange={handleChange}
              className="text-field"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Max Group Size */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Max Group Size"
              variant="outlined"
              fullWidth
              name="maxGroupSize"
              value={tourDetails.maxGroupSize}
              onChange={handleChange}
              className="text-field"
            />
          </Grid>

          {/* Tour Type */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="tour-type-label">Tour Type</InputLabel>
              <Select
                labelId="tour-type-label"
                name="type"
                value={tourDetails.type}
                onChange={handleChange}
                label="Tour Type"
                className="text-field"
              >
                <MenuItem value="Hiking">Hiking</MenuItem>
                <MenuItem value="Camping">Camping</MenuItem>
                <MenuItem value="Sightseeing">Sightseeing</MenuItem>
                <MenuItem value="Adventure">Adventure</MenuItem>
                {/* Add other tour types as needed */}
              </Select>
            </FormControl>
          </Grid>

          {/* Custom Image Upload Field */}
          <Grid item xs={12} container justifyContent="center">
            <FormControl fullWidth className="file-upload">
              <InputLabel htmlFor="images"></InputLabel>
              <Input
                id="images"
                type="file"
                name="images"
                onChange={handleImageChange}
                multiple
                className="text-field"
                inputProps={{ 'aria-label': 'Upload images' }}
                style={{ display: 'none' }}
              />
            </FormControl>

            {/* Custom Button to Trigger File Input */}
            <Button
              variant="contained"
              color="secondary"
              component="label"
              htmlFor="images"
              className="custom-file-button"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}
            >
              Upload Images
              <input
                id="images"
                type="file"
                name="images"
                onChange={handleImageChange}
                multiple
                style={{ display: 'none' }}
              />
            </Button>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              className="submit-button"
            >
              Add Tour
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddTour;
