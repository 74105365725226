import './TourList.css'

import { Button, Card, Col, Row } from 'react-bootstrap';

import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

// Static tours data
const tours = [
  { name: 'Tour 1', description: 'Explore the beauty of nature', price: '$100', duration: '5 days' },
  { name: 'Tour 2', description: 'A thrilling adventure awaits', price: '$150', duration: '7 days' },
  { name: 'Tour 3', description: 'Relax on the beaches', price: '$200', duration: '10 days' },
  { name: 'Tour 4', description: 'Discover the city lights', price: '$80', duration: '3 days' },
];

const TourList = () => {
  return (
    <Container className="list-tour-container">
    <div className="container py-5">
      <h2 className="text-center mb-4" style={{ fontWeight: 600, color: '#333' }}>Tour List</h2>

      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
        {tours.map((tour, index) => (
          <Col key={index}>
            <Card className="shadow-sm border-0 rounded">
              <Card.Body>
                <Card.Title>{tour.name}</Card.Title>
                <Card.Text className="text-muted">{tour.description}</Card.Text>
                <Card.Text className="font-weight-bold">{tour.price} | {tour.duration}</Card.Text>
                <Button
                  variant="primary"
                  as={Link}
                  to="/tour-details"
                  className="w-100"
                >
                  View Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      
    </div>
    </Container>
  );
};

export default TourList;
