import './Sidebar.css';

import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import { assets } from '../assest/assest';

const Sidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));  // Check if screen width is below the 'md' breakpoint
  const [open, setOpen] = useState(false);  // State to manage whether the sidebar is open or closed

  const toggleDrawer = () => {
    setOpen(!open);  // Toggle sidebar visibility
  };

  return (
    <Box>
      {/* AppBar (Header) with hamburger menu on small screens */}
      {isMobile && (
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleDrawer}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Tour Management</Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar Drawer (will be temporary on small screens, permanent on large screens) */}
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            backgroundColor: '#333',  // Sidebar background color
            color: 'white',
            paddingTop: '20px',  // Adding some top padding for better visual alignment
          },
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,  // Ensure it works better on mobile
        }}
      >
        {/* Profile Image Section */}
        <Box sx={{ textAlign: 'center', padding: '10px', marginBottom: '20px' }}>
          <img
            src={assets.profile} // Replace with the path to your profile image
            alt="Profile"
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',  // Makes the image circular
              objectFit: 'cover',   // Ensures the image fits well inside the circle
              marginBottom: '10px',  // Space between image and text
            }}
          />
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 600 }}>
            John Doe  {/* Replace with dynamic username or profile name */}
          </Typography>
        </Box>

        {/* Sidebar Menu */}
        <List>
          <ListItem button component={Link} to="/add-tour">
            <ListItemIcon>
              <AddIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Add Tour" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/tour-list">
            <ListItemIcon>
              <ListAltIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="List Tours" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/tour-details">
            <ListItemIcon>
              <InfoIcon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Tour Details" />
          </ListItem>
        </List>
      </Drawer>

      {/* Main content area */}
      <Box sx={{ marginLeft: isMobile ? 0 : 240, transition: 'margin 0.3s' }}>
        {/* Main content goes here */}
      </Box>
    </Box>
  );
};

export default Sidebar;
